import { GetStaticProps } from 'next';
import { PageNotFound } from '../../components/templates/PageNotFound';
import { getTranslationsProps } from '../utils/getServerSideProps';

// NOTE: 404ページはSSGで生成される
// https://nextjs.org/docs/pages/building-your-application/routing/custom-error
export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: { ...(await getTranslationsProps(locale ?? 'ja')) },
});

// TODO: https://github.com/avita-co-jp/add-customer-front/pull/72 がマージされ次第
//  共通化されたエラーページに置き換える
const NotFoundPage = () => <PageNotFound />;
export default NotFoundPage;
