import { useTranslation } from 'next-i18next';
import styles from './PageNotFound.module.scss';
import { ErrorContent } from '../../atoms/ErrorContent';

export const PageNotFound = (): JSX.Element => {
  const { t } = useTranslation('error');
  return (
    <div className={styles.container}>
      <div className={styles.pageNotFound}>
        <ErrorContent status="404">
          <p>{t('404error.p1')}</p>
          <p>{t('404error.p2')}</p>
        </ErrorContent>
      </div>
    </div>
  );
};
